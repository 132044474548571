import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, HashRouter } from 'react-router-dom';
import './scss/style.scss';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/Auth/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const VerifyEmailAccount = React.lazy(() => import('./views/verify-user-email')); // User email verification
// const Forgot = React.lazy(() => import('./views/Auth/forgot/Forgot'));

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const App = props => {
	const state = useSelector(state => state);
	const user = state.user;
	const page = state.page;
	const auth = state.auth;
	return (
		<HashRouter>
			<React.Suspense fallback={loading}>
				<Switch>
					<Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
					<Route exact path="/verify_email" name="Account Verification" render={props => <VerifyEmailAccount {...props} />} />

					<Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
					<Route exact path="/401" name="Page 401" render={props => <Page401 {...props} />} />
					<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
					{
						auth && auth._id ? (
							<Route path="/" name="Home" render={props => <TheLayout {...props} auth={auth} user={user} page={page} />} />
						) : <Redirect from="*/*" to="/login" exact />
					}
					{/* <Route exact path="/forgot" name="Forgot Password Page" render={props => <Forgot {...props} />} /> */}
				</Switch>
			</React.Suspense>
		</HashRouter>
	);
}

export default React.memo(App);
