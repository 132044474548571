import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTasks,
  faListAlt,
  faUserTag,
  faHandshake,
  faUserCog,
  faUserFriends,
  faTachometerAlt,
  faComment,
  faRss,
  faUser,
  faLink,
} from '@fortawesome/free-solid-svg-icons';

const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    type: 'read',
    module: 'dashboard',
    icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faTachometerAlt} />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Mobile Menu Items',
    to: '/mobile-menu-items',
    type: 'read',
    module: 'mobile_menu_links',
    icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faLink} />,
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Feed & Post Management'],
    module: 'feed',
    type: 'read',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Feed Management',
    route: '/feed',
    module: 'feed',
    type: 'read',
    icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faRss} />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Post List',
        module: 'feed',
        type: 'read',
        to: '/feed/list',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faListAlt} />,
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Create Post',
        module: 'feed',
        type: 'write',
        to: '/feed/manage',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faTasks} />,
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Personal Post Management',
    route: '/post',
    module: 'post',
    type: 'read',
    icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faComment} />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Post List',
        module: 'post',
        type: 'read',
        to: '/post/list',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faListAlt} />,
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Create Post',
        module: 'post',
        type: 'write',
        to: '/post/manage',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faTasks} />,
      },
    ],
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Admin & User Management'],
    module: 'user',
    type: 'read',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Admin & User',
    route: '/user',
    module: 'user',
    type: 'read',
    icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faUser} />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'User List',
        module: 'user',
        type: 'read',
        to: '/user/list',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faListAlt} />,
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Create User',
        module: 'user',
        type: 'write',
        to: '/user/manage',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faTasks} />,
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Notification Groups',
    route: '/notification-groups',
    module: 'notification-groups',
    type: 'read',
    icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faUserFriends} />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Notification Group List',
        module: 'notification-groups',
        type: 'read',
        to: '/notification-group/list',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faListAlt} />,
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Create Group',
        module: 'notification-groups',
        type: 'write',
        to: '/notification-group/create',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faTasks} />,
      },
    ],
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Roles & Rights Management'],
    module: 'right_management',
    type: 'read',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Roles & Rights',
    route: '/role_right',
    module: 'role_to_right',
    type: 'read',
    icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faUserCog} />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Manage Roles',
        module: 'user_role',
        type: 'read',
        to: '/role_right/roles',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faUserTag} />,
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Assign Rights To Roles',
        module: 'role_to_right',
        type: 'write',
        to: '/role_right/manage',
        icon: <FontAwesomeIcon className="c-sidebar-nav-icon" icon={faHandshake} />,
      },
    ],
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
]

export default _nav
